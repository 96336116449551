<template>
  <div class="user">
    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="80px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="用户类型:" prop="">
              <el-radio-group v-model="queryInfo.type" @change="search">
                <el-radio-button label="">全部 </el-radio-button>
                <el-radio-button label="0">普通用户 </el-radio-button>
                <el-radio-button label="1">秘书长用户 </el-radio-button>
                <el-radio-button label="2">会员用户 </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div class="tab_txt">
      <div class="input-with">
        <el-input placeholder="请输入用户昵称" v-model="queryInfo.param" class="input-with-select">
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
      </div>
      <el-table
        v-loading="loading"
        @sort-change="changeSort"
        :data="listData"
        stripe
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        row-key="id"
      >
        <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
        <el-table-column label="编号" align="center" type="index">
          <template slot-scope="scope">
            <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微信头像" align="center">
          <template>
            <img src="../../assets/image/Group 2821.png" width="42px" height="42px" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="微信昵称" prop="nickName" align="center"></el-table-column>
        <el-table-column label="姓名" prop="realName" align="center"></el-table-column>
        <el-table-column label="所属机构" prop="chamberCommerceName" align="center">
          <template v-slot="{ row }">
            <span>{{ row.chamberCommerceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="机构职务" prop="content" align="center">
          <template v-slot="{ row }">
            <span>{{ row.job }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户类型" prop="" width="120px" align="center">
          <template v-slot="{ row }">
            <span class="span_user" style="background: #edf4ff; color: #4e93fb" v-if="row.type == 0"
              >普通用户</span
            >
            <span class="span_user" style="background: #fff2e8; color: #ff7d18" v-if="row.type == 1"
              >秘书长用户</span
            >
            <span class="span_user" style="background: #e8fff1; color: #00b147" v-if="row.type == 2"
              >会员用户</span
            >
          </template>
        </el-table-column>
        <el-table-column label="手机号" align="center">
          <template v-slot="{ row }">
            <span>{{ row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="IP/城市名" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.ip }}/{{ row.cityName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="最近登录时间"
          sortable
          prop="loginDate"
          align="center"
          width="130px"
        ></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="160px"
        >
          <template slot-scope="scope">
            <el-button style="color: #146aff" type="text" @click="userinfoClick(scope.row)"
              >详情
            </el-button>
            <!-- <el-button style="color: #ff4646" type="text" @click="torecord(scope.row)"
              >冻结
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { userlist } from '@/api/wechatUser'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  param: null, //用户昵称
  type: '', //用户类型
  loginDate: null
})
export default {
  name: 'Userment',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0
    }
  },
  created() {
    this.search()
  },
  methods: {
    async search() {
      this.loading = true
      const res = await userlist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      }
    },
    userinfoClick(item) {
      this.$router.push({
        name: 'userinfo',
        query: { id: item.id }
      })
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'loginDate') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.queryInfo.loginDate = 'asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时
          this.queryInfo.loginDate = 'desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.search()
        }
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  .span_user {
    display: inline-block;
    width: 112px;
    height: 33px;
    line-height: 33px;
    text-align: center;
  }
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    background: #ffffff;
    margin-top: 20px;
    padding: 20px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;

    .input-with-select {
      width: 400px;
      float: right;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
</style>
